<template>
  <div class="profile-corner mr-2">
    <b-button
      size="sm"
      class="button-export-style  ml-2"
      v-b-modal.validation-statue-dossier
      title="Modifier les statuts"
    >
      <font-awesome-icon icon="pen"
    /></b-button>
    <!-- Validation statue dossier Modal -->
    <b-modal
      ref="validation-statue-dossier"
      id="validation-statue-dossier"
      no-close-on-backdrop
      :hide-footer="true"
      :title="'Modification globale de statut '"
      @hidden="hideModal('validation-statue-dossier')"
      modal-class="modal-validation-statut-AV"
    >
      <b-form-group
        id="fieldset-horizontal-type"
        label-cols-sm="5"
        label-cols-lg="3"
        content-cols-sm
        content-cols-lg="9"
        label="Statut : "
        label-for="type-modal"
      >
        <b-form-select
          id="fieldset-horizontal-type"
          v-model="currentStatut"
          :options="[
            { text: 'Facture à éditer', value: 'Facture à éditer' },
            { text: 'PAYÉ', value: 'paye' },
            { text: 'ADP', value: 'non paye' }
          ]"
          required
          class="b-form-select-raduis "
        ></b-form-select>
      </b-form-group>
      <b-form-group
        id="fieldset-horizontal-DATA"
        label-cols-sm="5"
        label-cols-lg="4"
        content-cols-sm
        content-cols-lg="7"
        label="Date de paiement : "
        label-for="DATA-modal"
        v-if="currentStatut == 'paye'"
      >
        <date-picker
          value-type="format"
          format="MM/DD/YYYY"
          type="date"
          :value="dateArr"
          class="inputDateTemplate"
          @input="handleDateTimePicker"
        ></date-picker>
      </b-form-group>
      <div class="form-modal-custom-style mt-1 mb-1">
        <div class="messageError text-center">
          <div v-if="error" class="error">
            <ul v-if="Array.isArray(error)">
              <li v-for="(e, index) in error" :key="index">
                {{ e }}
              </li>
            </ul>
            <span v-else>{{ error }}</span>
          </div>
        </div>
        <div class="actionModel">
          <b-button class="button-valide-style " @click="handleChangeStatue">
            <span>
              Générer
              <div v-if="loading" class="loading ml-2">
                <div class="spinner-border" role="status"></div></div
            ></span>
          </b-button>
        </div>
      </div>
    </b-modal>
    <!-- END Validation Statue dossier Modal -->
    <!-- <b-form-group
      label=""
      label-for="regie"
      class="vue-select-modal-champ vue-select-modal-champ-custom mb-0"
      v-if="ComputedEditable && computedCheckAll"
    >
      <multiselect
        v-model="currentStatut"
        :options="[
          { name: 'PAYÉ', value: 'paye' },
          { name: 'ADP', value: 'non paye' }
        ]"
        :close-on-select="true"
        :clear-on-select="true"
        label="name"
        track-by="name"
        :searchable="false"
        :preselect-first="false"
        :showLabels="false"
        required
        :allow-empty="false"
        placeholder="Modifier le statut"
        @input="handleChangeStatue"
        class="select-vue-component-style select-vue-component-style-type"
      >
      </multiselect>
    </b-form-group> -->
  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';
export default {
  props: { name: { required: true } },
  data() {
    return {
      currentStatut: 'paye',
      dateArr: null,
      loading: false,
      error: null
    };
  },
  computed: {
    ...mapGetters(['cantUpdate', 'getDataTh']),
    ComputedEditable() {
      return this.cantUpdate(this.name);
    },
    computedRows() {
      if (!this.getDataTh) {
        return [];
      }
      return this.getDataTh;
    },
    computedCheckAll() {
      let tab = this.computedRows.filter(item => item.check == true);
      if (tab.length) {
        return true;
      }
      return false;
    }
  },
  methods: {
    ...mapActions(['changeStatutInAnalyseValidation']),
    handleDateTimePicker(data) {
      this.dateArr = data;
    },
    async handleChangeStatue() {
      if (this.dateArr == null) {
        this.error = 'Date obligatoire';
        return;
      }
      let textStatue =
        this.currentStatut == 'paye'
          ? 'PAYÉ'
          : this.currentStatut == 'non paye'
          ? 'ADP'
          : this.currentStatut;
      let statut =
        'Êtes-vous sur de vouloir attribue le statut ' +
        "'" +
        textStatue +
        "'" +
        ' pour tous les dossiers sélectionnés  ?';
      // let flatpickrInstance;
      // const flatpickr = require('flatpickr').default;
      this.$swal.fire({
        title: statut,
        type: 'info',
        icon: 'info',
        locale: 'fr',
        allowOutsideClick: () => !this.$swal.isLoading(),

        // html:
        //   this.currentStatut == 'paye'
        //     ? '<label>Date de paiement</label><input class="swal2-input" id="myDatePicker">'
        //     : '',
        stopKeydownPropagation: false,

        preConfirm: async () => {
          // let dateArr = '';
          // if (this.currentStatut == 'paye') {
          //   if (flatpickrInstance.selectedDates.length == 0) {
          //     this.$swal.showValidationMessage(`Date obligatoire`);
          //     return;
          //   }
          //   dateArr = moment(flatpickrInstance.selectedDates[0]).format(
          //     'MM/DD/YYYY'
          //   );
          // }
          this.$refs['validation-statue-dossier'].hide();
          const responce = await this.changeStatutInAnalyseValidation({
            statut: this.currentStatut,
            date: this.dateArr
          });
          this.resetModal();
          if (responce && responce.succes) {
            this.currentStatut = null;
            this.checkAll = false;
            let textSucces =
              '<div class="mb-2"><b>Les statuts modifiés avec succes:' +
              responce.data.success.length +
              '</b></div>';
            if (responce.data.success.length > 0) {
              textSucces = textSucces + '<ul class="ml-4">';
              for (let i = 0; i < responce.data.success.length; i++) {
                textSucces =
                  textSucces + '<li>' + responce.data.success[i] + '</li>';
              }
              textSucces = textSucces + '</ul>';
            }
            let textFailed =
              '<div class="mb-2"><b> Les statuts non modifiés: ' +
              responce.data.errors.length +
              '</b></div> <div></div>';

            if (responce.data.errors.length > 0) {
              textFailed = textFailed + '<ul class="ml-4">';
              for (let j = 0; j < responce.data.errors.length; j++) {
                textFailed =
                  textFailed + '<li>' + responce.data.errors[j] + '</li>';
              }
              textFailed = textFailed + '</ul>';
            }
            this.$swal({
              icon: 'info',
              html:
                '<blod><div>' +
                textSucces +
                '</div><div></div>' +
                textFailed +
                ' <div></div></blod>',
              confirmButtonText: 'OK !',
              preConfirm: async () => {
                this.$emit('refreshData');
              }
            });
          }
        }
        // willOpen: () => {
        //   if (this.currentStatut == 'paye') {
        //     flatpickrInstance = flatpickr('#myDatePicker', {
        //       dateFormat: 'm/d/Y',
        //       format: 'm/d/Y'
        //     });
        //   }
        // }
      });
    },
    hideModal(ref) {
      this.$refs[ref].hide();
      this.resetModal();
    },
    resetModal() {
      this.currentStatut = 'paye';
      this.loading = false;
    }
  },
  components: { DatePicker },
  mounted() {
    let now = new Date();
    this.dateArr = moment(now).format('MM/DD/YYYY');
  }
};
</script>

<style scoped lang="scss">
.inputDateTemplate {
  width: 100% !important;
}
</style>
<style lang="scss">
.modal-validation-statut-AV {
  .modal-dialog {
    top: 20% !important;
  }
}
</style>
